<template>
	<h1>Manage Plan Groups</h1>
	<div v-if="dataError" class="critical-error">An error has occurred.</div>
	<div v-else-if="planGroups === null" class="loading-indicator">
		<img src="@/assets/images/loading.gif">
	</div>
	<div v-else>
		<div id="plan-group-list-wrapper">
			<div id="filters" class="form">
				<div>
					<label for="customer-type-selection">Customer Type</label>
					<select id="customer-type-selection" v-model="displayCustomerType" @change="loadPlanGroups(true, true)">
						<option value="all">All</option>
						<option value="corporate">Corporate</option>
						<option value="residential">Residential</option>
					</select>
				</div>
				<div>
					<label for="service-type-selection">Service Type</label>
					<select id="service-type-selection" v-model="displayServiceType" @change="loadPlanGroups(true, true)">
						<option value="all">All</option>
						<option value="NBN">NBN</option>
						<option value="Mobile">Mobile</option>
						<option value="CloudPBX">Cloud PBX</option>
						<option value="InboundVoice">Inbound Voice</option>
					</select>
				</div>
				<button v-if="canCreate" type="button" @click="createPlanGroup">Create New Plan Group</button>
			</div>
			<div v-show="loadingPlanGroups" class="loading-indicator">
				<img src="@/assets/images/loading.gif">
			</div>
			<div id="plan-group-list" :class="{hidden: loadingPlanGroups}">
				<div v-if="planGroups.length == 0" class="no-plan-groups">
					There are no plan groups to display.
				</div>
				<table v-else class="data-table">
					<tr>
						<th>Plan Group Name</th>
						<th>Customer Type</th>
						<th>Service Type</th>
						<th>Promo Code</th>
						<th v-if="canEdit || canDelete" :colspan="(canEdit && canDelete) ? 2 : 1">{{(canEdit && canDelete) ? 'Actions' : 'Edit'}}</th>
					</tr>
					<tr v-for="(planGroup, index) in planGroups">
						<td>{{planGroup.group_name}}</td>
						<td>{{capitaliseFirstLetter(planGroup.customer_type)}}</td>
						<td>{{formatServiceType(planGroup.service_type)}}</td>
						<td :class="{'empty-cell': !planGroup.promo_code}">{{planGroup.promo_code ?? 'N/A'}}</td>
						<td v-if="canEdit" class="edit-cell" @click="editPlanGroup(planGroup)" title="Edit Plan Group">&#x1F589;</td>
						<td v-if="canDelete" class="delete-cell" @click="deletePlanGroup(planGroup)" title="Delete Plan Group">X</td>
					</tr>
				</table>
				<pagination v-model="displayPage" :data="planGroups" @page-changed="loadPlanGroups"></pagination>
			</div>
		</div>
		<edit-plan-group-form v-if="editingPlanGroup" :plan-group-id="editedPlanGroup" @close="editingPlanGroup = false" @completed="loadPlanGroups(false)"></edit-plan-group-form>
		<delete-plan-group-confirmation v-if="deletingPlanGroup" :plan-group="deletingPlanGroup" @close="deletingPlanGroup = null" @completed="loadPlanGroups(false)"></delete-plan-group-confirmation>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination';
	import EditPlanGroupForm from '@/components/Modals/PlanGroups/EditPlanGroupForm';
	import DeletePlanGroupConfirmation from '@/components/Modals/PlanGroups/DeletePlanGroupConfirmation';
	import {mapGetters} from 'vuex';
	
	export default {
		data() {
			return {
				planGroups: null,
				displayCustomerType: 'all',
				displayServiceType: 'all',
				loadingPlanGroups: false,
				dataError: false,
				editingPlanGroup: false,
				editedPlanGroup: null,
				deletingPlanGroup: null,
				displayPage: 1
			}
		},
		computed: {
			canCreate() { // Used to determine whether the authenticated user has the appropriate permission to create plan groups.
				return this.hasPermission('plans', 'create');
			},
			canEdit() { // Used to determine whether the authenticated user has the appropriate permission to edit plan groups.
				return this.hasPermission('plans', 'edit');
			},
			canDelete() { // Used to determine whether the authenticated user has the appropriate permission to delete plan groups.
				return this.hasPermission('plans', 'delete');
			},
			...mapGetters(['hasPermission'])
		},
		components: {
			Pagination, EditPlanGroupForm, DeletePlanGroupConfirmation
		},
		async created() { // When the page is loaded, get the list of plan groups.
			await this.loadPlanGroups();
		},
		methods: {
			async loadPlanGroups(refresh = true, resetPagination = false) { // Performs the API request to get the list of plan groups.
				// If the pagination needs to be reset, set the displayed page to Page 1.
				if(resetPagination) {
					this.displayPage = 1;
				}
				
				// If we're visually refreshing the page (rather than stealthily updating the plan group list), display the loading indicator.
				if(refresh) {
					this.loadingPlanGroups = true;
				}
				
				// Generate the query string for the API request based on the filters selected.
				let queryString = '';
				const filters = {'customer-type': this.displayCustomerType, 'service-type': this.displayServiceType};
				for(const filter in filters) {
					const filterValue = filters[filter];
					if(filterValue != 'all') { // Each query string argument is only required if the filter is actually applied.
						queryString += `&${filter}=${filterValue}`;
					}
				}
				
				// Perform the API request to get the list of plan groups.
				try {
					const response = await this.HTTP.get(`plan-groups?page=${this.displayPage}` + queryString);
					this.planGroups = response.data.data;
				} catch(error) { // If there was an error obtaining the plan group list, display the generic error message.
					this.dataError = true;
				} finally { // Regardless of whether the API request was successful, hide the loading indicator.
					this.loadingPlanGroups = false;
				}
			},
			createPlanGroup() { // Displays the modal for creating a new plan group.
				this.editedPlanGroup = null;
				this.editingPlanGroup = true;
			},
			editPlanGroup(planGroup) { // Displays the edit plan group modal for the plan group at the given index in the plan groups array.
				this.editedPlanGroup = planGroup.id;
				this.editingPlanGroup = true;
			},
			deletePlanGroup(planGroup) { // Displays the deletion confirmation modal for the plan group at the given index in the plan groups array.
				this.deletingPlanGroup = planGroup;
			},
			capitaliseFirstLetter(string) { // Returns the input string with the first letter capitalised.
				return string.charAt(0).toUpperCase() + string.slice(1);
			},
			formatServiceType(serviceType) { // Given the service type for a plan group, returns the user-friendly representation.
				switch(serviceType) {
					case 'CloudPBX':
						return 'Cloud PBX';
					case 'InboundVoice':
						return 'Inbound Voice';
					default:
						return serviceType;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	#plan-group-list-wrapper {
		width:max-content;
		margin:0 auto;
	}
	
	#plan-group-list.hidden {
		visibility:hidden;
	}
	
	#filters {
		text-align:right;
		margin-bottom:20px;
		
		div {
			text-align:left;
			display:inline-block;
			margin-bottom:0;
		}
		
		label {
			display:block;
		}
		
		div, button {
			margin-left:20px;
		}
	}
	
	.no-plan-groups {
		text-align:center;
	}
	
	.empty-cell {
		color:var(--inactive-record-color);
	}
</style>