<template>
	<modal-view :title="creatingPlanGroup ? 'Create Plan Group' : 'Edit Plan Group'" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="savingPlanGroup || loadingPlanGroup" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="planGroupSaved" class="success-message">Plan Group {{creatingPlanGroup ? 'Created' : 'Updated'}} Successfully</div>
		<div v-else>
			<form class="data-form" @submit.prevent="savePlanGroup">
				<div>
					<label for="plan-group-name">Plan Group Name</label>
					<span class="required">*</span>
					<input type="text" id="plan-group-name" v-model="planGroupName" @input="errorMessage = null" maxlength="255" />
				</div>
				<div>
					<label for="customer-type">Customer Type</label>
					<span class="required">*</span>
					<select id="customer-type" v-model="customerType" :disabled="!creatingPlanGroup" @change="errorMessage = null">
						<option value="" disabled>Select...</option>
						<option value="corporate">Corporate</option>
						<option value="residential">Residential</option>
					</select>
				</div>
				<div>
					<label for="service-type">Service Type</label>
					<span class="required">*</span>
					<select id="service-type" v-model="serviceType" :disabled="!creatingPlanGroup" @change="errorMessage = null">
						<option value="" disabled>Select...</option>
						<option value="NBN">NBN</option>
						<option value="Mobile">Mobile</option>
						<option value="CloudPBX">Cloud PBX</option>
						<option value="InboundVoice">Inbound Voice</option>
					</select>
				</div>
				<div>
					<label for="promo-code">Promo Code</label>
					<input type="text" id="promo-code" v-model="promoCode" @input="errorMessage = null" maxlength="255" />
				</div>
				<div class="button-wrapper">
					<button type="submit">{{creatingPlanGroup ? 'Create' : 'Save'}} Plan Group</button>
				</div>
			</form>
			<div class="critical-error">{{errorMessage}}</div>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	
	export default {
		props: {
			planGroupId: String
		},
		data() {
			return {
				planGroup: null,
				planGroupName: '',
				customerType: '',
				serviceType: '',
				promoCode: '',
				savingPlanGroup: false,
				planGroupSaved: false,
				errorMessage: null,
				dataError: false
			}
		},
		computed: {
			creatingPlanGroup() { // Used to determine whether a new plan group is being created, or an existing plan group is being updated.
				return (this.planGroupId === null);
			},
			loadingPlanGroup() { // Used to determine whether the updated plan group details are being loaded from the API.
				return (!this.creatingPlanGroup && this.planGroup === null);
			}
		},
		components: {
			ModalView
		},
		async created() { // When the modal is loaded, check if we are editing an existing plan group, and if so, populate the form with the existing plan group details.
			if(!this.creatingPlanGroup) {
				await this.getPlanGroupDetails();
			}
		},
		methods: {
			async getPlanGroupDetails() { // Performs the API request to get the plan group details for the given plan group.
				try {
					const response = await this.HTTP.get('plan-groups/' + this.planGroupId);
					this.planGroup = response.data.data;
					
					this.planGroupName = this.planGroup.group_name;
					this.customerType = this.planGroup.customer_type;
					this.serviceType = this.planGroup.service_type;
					this.promoCode = this.planGroup.promo_code;
				} catch(error) { // If there was an error obtaining the plan group details, display the generic error message.
					this.dataError = true;
				}
			},
			async savePlanGroup() { // Performs the API request to create or update the given plan group.
				if(this.validateForm()) {
					try {
						// Replace the edit plan group form with a loading indicator.
						this.savingPlanGroup = true;
						
						// Set the data to create or update the plan group.
						const data = {group_name: this.planGroupName, promo_code: this.promoCode};
						if(this.creatingPlanGroup) { // The customer type and service type are only allowed if a new plan group is being created, since these fields can't be modified once a plan group is created.
							data.customer_type = this.customerType;
							data.service_type = this.serviceType;
						}
						
						// Perform the API request to create or update the given plan group.
						(this.creatingPlanGroup) ? await this.createPlanGroup(data) : await this.updatePlanGroup(data);
						
						// If the plan group was updated successfully, display the success message and instruct the parent component to reload the plan group list.
						this.planGroupSaved = true;
						this.$emit('completed');
					} catch(error) { // If there was an error saving the plan group, display an error message below the edit plan group form.
						if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "A plan group with the given promo code already exists.") { // If the error is that the given promo code already exists, display the specific error message from the API response.
							this.errorMessage = error.response.data.error;
						} else { // For any other error, display a generic error message.
							this.errorMessage = 'An error has occurred.';
						}
					} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
						this.savingPlanGroup = false;
					}
				}
			},
			async createPlanGroup(data) { // Performs the API request to create a plan group with the given data.
				await this.HTTP.post('plan-groups', data);
			},
			async updatePlanGroup(data) { // Performs the API request to update the given plan group with the given data.
				await this.HTTP.put('plan-groups/' + this.planGroup.id, data);
			},
			validateForm() { // Validates the data provided in the form.
				// Set the list of required fields.
				const requiredFields = {'plan group name': this.planGroupName};
				if(this.creatingPlanGroup) { // During service plan group creation, there are additional fields that are required.
					requiredFields['customer type'] = this.customerType;
					requiredFields['service type'] = this.serviceType;
				}
				
				// Ensure that all required fields in the edit form have been filled in.
				for(const field in requiredFields) {
					const value = requiredFields[field];
					if(value == '') {
						this.errorMessage = `The ${field} is required.`;
						return false;
					}
				}
				
				return true;
			}
		}
	}
</script>

<style scoped lang="scss">
	.data-form {
		width:50%;
		
		input, select {
			width:100%;
		}
	}
</style>